import React, {useState, useEffect} from 'react';
import './Profile.css';
import Header_2 from '../../../components/Yard/header/Header_2';
import Menu from './Menu';
import signout from '../../../images/user/signout.svg';
import {navigate} from 'gatsby';
import {Routes} from '../../../utils/route';
import {flushYardDataOnLogout} from '../../../actions/yardActions/YardActions';
import {connect} from 'react-redux';
import {Spin} from 'antd';
const Profile = props => {
  const [loading, setLoading] = useState (true);
  const roleid=typeof window !== 'undefined'? localStorage.getItem("role_id"):"";
  const isShow= roleid==99 || roleid==51 ||roleid==52;
  useEffect (() => {
    const callback = val => {
      if (val === true) {
        setLoading (false);
      }
    };
    Routes ('yard', callback);
  }, []);

  const signOut = () => {
    navigate ('/auth');
    props.flushYardDataOnLogout ();
    localStorage.removeItem ('token');
    localStorage.removeItem ('token');
    localStorage.removeItem ('role_id');
    localStorage.removeItem ('data');
    localStorage.removeItem ('userName');
    localStorage.removeItem('yard')
  };
  if (!loading) {
    return (
      <section className="profile">
        <Header_2 />
        <div className="profile_container">
          {isShow?<Menu  name="Switch To Admin"  onClick={()=>navigate("/myYard")}/>:""}
         <Menu img={signout} name="Sign out" onClick={() => signOut ()} />
        </div>
      </section>
    );
  } else {
    return <div className="spinner-main"><Spin /></div>;
  }
};

export default connect (null, {flushYardDataOnLogout}) (Profile);
