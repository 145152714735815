import React from 'react';
import {UserSwitchOutlined} from '@ant-design/icons';

const Menu = (props) => {
  return (
    <div className="yard-profile-nav" onClick={() => props.onClick()}>
      {props.img?<img src={props.img} alt="nav images" />:<UserSwitchOutlined style={{fontSize:'30px',color:'rgb(89 190 245 / 1)'}}/>}
      <p style={{ marginBottom: 0 }}>{props.name}</p>
    </div>
  );
};
export default Menu;
