import React,{useState,useEffect} from 'react'
import { Link } from "gatsby"
import user from "../../../images/user/user.svg"
import {ArrowLeftOutlined} from '@ant-design/icons'
import "./Header_2.css"
const Header_2 = () => {
    const [username,setUserName]=useState('')
    const [email,setEmail]=useState('')
    useEffect(()=>{
 const data=JSON.parse(localStorage.getItem('data'))
            if(data){
                setUserName(data.first_name+' '+data.last_name)
                setEmail(data.email)
            }
    },[])
    return (
        <div className="header_2">
            <div className="header_2_top">
                <Link to="/yard/home"><ArrowLeftOutlined/></Link>
                <p>Profile</p>
            </div>
            <div className="header_2_bottom">
                <img src={user} alt="user img" />
                <p className="user_name">{username}</p>
                <p className="user_email">{email}</p>
            </div>
            <span>
               <style> {   
                `
                .header_2 p{
                    margin-bottom:0px;
                }
                `}</style>
            </span>
        </div>
    )
}
export default Header_2;