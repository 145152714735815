import {types} from '../type.js';
import API from '../../api';
const {ticketList} = API;

export const fetchYardList = () => async (dispatch, getState) => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  const result = await ticketList ();

  if (result.data.success === true) {
    dispatch ({
      type: types.YARD_TICKET_LIST,
      payload: result.data.data,
    });
  }
  dispatch ({type: types.SHOW_LOADING, loading: false});
};

export const removeYardTicketAfterComplete = id => async dispatch => {
  dispatch ({
    type: types.YARD_TICKET_COMPLETE,
    id: id,
  });
};

export const flushYardDataOnLogout = () => dispatch => {
  dispatch ({
    type: types.FLUSH_YARD_LIST_DATA,
  });
};
